@import '../../../../styles/common/colors';

.container {
  padding: 20px 20px 16px 20px;
  border: 1px solid $isabeline;
  background-color: $white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.signing-link {
  background-color: $snow;
  padding: 6px 12px;
}
